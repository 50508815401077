*{
    margin:0px;
    padding:0px;
    font-family: 'Roboto';
}

.App{
    margin: auto;
    background-color: white;
    width: 100vw;
    height: 100vh;
}
.header{
    width: 100%;
    height: 50px;
    position: relative;
    background-color: white;
    color:black;
    font-size: 25px;
    font-family: 'Roboto';
    margin: 35px auto;
    z-index: 500;
    text-align: center;
}

.header a{
    text-decoration: none;
    color: green
}
.assemblyTable{
    position: relative;
    background-color: white;
    width: 100vw;
    height:50vw;
    margin:auto;
    text-align: center;
   

}

.breadboard{
    width: 800px;
    height: 558px;
    position: relative;
    margin :0 auto;
    top: 0px;
    background-color: rgb(239, 239, 239);
    border-radius: 10px;
   
  
}

.cdashboard{
    width: 800px;
    height: 200px;
    position: relative;
    bottom: 0px;
    margin: 0 auto;
    margin-top: 43px;
    background-color: rgb(239, 239, 239);
    border-radius:10px;
    /* border-width: 2px;
    border-style: solid; */

    padding: 10px auto;
    
}

.adashboard{
    width: 800px;
    height: auto;
    position: relative;
    font-size: 20px;
    bottom: 0px;
    margin: 0 auto;
    margin-top: 43px;
    padding: 25px 0 ;
    background-color: rgb(239, 239, 239);
    border-radius:10px;
    /* border-width: 2px;
    border-style: solid; */

    padding: 10px auto;
    
}

.components{
    position: relative;
    width: 800px;;
    height: 190px;
    margin:5px auto;
    /* background-color: red; */
    display: inline-flex;
    flex-wrap: wrap;
    left:10px;
}
.component{
    width: 115px;
    height: 62px;
    border-radius: 10px;
    background-color: rgb(57, 51, 51);
    margin:auto 20px;
    overflow: hidden;
    color:white;
    font-size: 70px;
    text-align: center;
}
.component:hover{
    background-color: rgb(109, 106, 106);;
    color: white;
}

.componentImages{
    margin: auto;
    /* background-color: red; */
    position: relative;
    top:-10px;
}

.componentIcon{
    height: auto;
    width: auto;
    margin: auto;
    /* background-color: red; */
    position: relative;
    top: -5px;
}

.Details{
    width: 220px;
    height: 70px;
    background-color: rgba(43, 37, 37,0.81);
    border-radius: 10px;
    position:absolute;
    top:39px;
    left:29px;
    font-size: 20px;
    text-align: left;
    z-index: 200;
    /* padding-left: 10px; */
    /* padding-top: 10px; */
    display:block;
    flex-wrap: wrap;
}
.Details p{
    font-family: 'Roboto';
  
    margin:7.5px 18px;
    color: white;
}
.displayValue{
    border-style:solid;
    border: none;
    text-align: center;
    color: black;
    background-color: white;
    width: 60px;
    position: absolute;
    top: 45px;
    left:78px;
    border-radius: 10px;
}
.displayUnits{
    position: absolute;
    margin: 0;
    left:145px;
    top:45px;
    border-style:solid;
    border: none;
    border-radius: 10px;
    text-align: center;
}
.changeBtn{
    position: absolute;
}

.Graph{
    width: 766px;
    height: 520px;
    margin: auto;
    /* background-color: red; */
    margin-bottom: 20px;
    display: inline-flex;
    flex-wrap: wrap;
    position:relative;
    top:15px;
    left: 5px;
    font-size: 70px;   
}
.componentsOnBreadBoard{
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    left: -5px;
    pointer-events: none; /*This disables the any clicking event on the "open space" where components are on top of the nodes*/
    /* background-color: rgba(158, 45, 45,0.5); */
    /* transform: rotate(0deg); */
   
}

.singleComponentOnBreadBoard{
    pointer-events:fill; 
    z-index: 100;
    /* background-color: red; */
    /* color: green; */
}
.singleComponentOnBreadBoard:hover{
    cursor: pointer;
    /* background-color: red; */
    /* color: orange; */
}

.singleComponentOnBreadBoard.selected{
    /* box-shadow: 0 0 10px 10px rgb(49, 158, 45); */
    border-radius: 50%;
    background-color: transparent;
}

.Node, .Node.enabled{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(222, 204, 204) ;
    margin: 10px;
    color: transparent; /* This removes the blinking line cursor */
}

.Node.enabled:hover{
    background-color: rgb(158, 45, 45);
    box-shadow: 0 0 5px 5px rgb(158, 45, 45);
}

.Node.selected{
    background-color: rgb(158, 45, 45);
} 
.Node.selected:hover{
    
    background-color: rgb(82, 153, 88);
    box-shadow: 0 0 5px 5px rgb(82, 153, 88);
} 

/* .connected checks if a node has connected to at least 2 components */
.Node.connected{
    background-color: rgb(82, 153, 88);
}

.selected{
    background-color: rgb(9, 121, 56);
}

.component.selected{
    color:black;
}
.component.selected:hover{
    background-color: rgb(9, 121, 56);
}

.wire{
    background-color: black;
    width: 0px;
    height: 3px;
    position: relative;
    border-radius: 30%;
    left: 5px;
    top:5px;
}

.userAccount{
    background-color:rgb(239, 239, 239);
    /* background-color: rgba(43, 37, 37,0.81); */
    width: 800px;
    height: 801px;
    position: relative;
    margin :0 auto;
    border-radius: 10px;
    top: -700px;
    z-index: 250;
}

.userHeader{
   
 color: black;
 position: relative;
 margin: 0 auto;
 margin-bottom: 15px;
 top: 10px;
 width: 670px;
 height: 50px;
 font-size: 36px;
 font-family: 'Roboto';
}
/* 
.userForm{

} */

.userInput{
    border-style: none;
    width: 650px;
    height: 80px;
    border-radius: 10px; 
    border-style: none;
    margin: 0 auto;
    margin-bottom: 30px;
    text-align: left;
    background-color: #D9D9D9;
    padding-left: 20px;
    font-size: 24px;
    font-weight: 300;
}

.userInput.s-btn{
    padding: 0;
    width: 670px; 
    background-color:  #393333;
    color:white;
    text-align: center; 
    font-weight: 500;
}
.userInput.btn{
    width: 670px;
    padding: 0;
    background-color: orange;
    color: white;
    text-align: center; 
    font-weight: 500;
} 

.userFooter p{
    font-weight: 300;
    font-size: 24px;
    margin-bottom: 10px;

}

.PrivacyPolicies{
    width: 100%;
    height: 10px;
    position: relative;
    bottom: 0px;
    margin: 0 auto;
    margin-top: 43px;
    margin-bottom: 20px;
    padding: 20px 0;
    background-color: #393333;
    text-align: left;
    
    /* border-width: 2px;
    border-style: solid; */

  
}

.PrivacyPolicies p {
    font-size: 14px;
    font-family: 'Times New Roman';
    color: red;
}

.PrivacyPolicies H1 {
    font-family: 'Calibri';
    text-align: left;
}

.PrivacyPolicies li {
    font-family: 'Calibri';
 
    list-style-type: "- ";
    margin: 10px 0;
}

.PrivacyPolicies a{
    text-decoration: none;
    color: white;
    margin-left: 3%
}

.Navigation{
    background-color: rgb(43, 37, 37);
    width: 100%;
    height: 70px;
    text-align: left;
    color: white;
    padding: 0 30px;
}

.Navigation a{
    text-decoration: none;
    color: white;
}
.Navigation h1{
    font-weight: 500;
    position: relative;
    top: 10px;
}

.advertComponent{
    position: relative;
    bottom: 0px;
    margin: 0 auto;
    margin-top: 43px;
    margin-bottom: 20px;
    padding: 25px 0;
    /* background-color: rgb(255, 255, 255); */
    text-align: center;
    border-radius:0px;
    
    /* border-width: 2px;
    border-style: solid; */

    padding: 10px auto;
}

.articleComponent{
    width: 93%;
    height: auto;
    position: relative;
    bottom: 0px;
    margin: 0 auto;
    margin-top: 43px;
    margin-bottom: 20px;
    padding: 25px 0;
    /* background-color: rgb(249, 245, 245); */
    text-align: left;
    border-radius:0px;
    
    /* border-width: 2px;
    border-style: solid; */
    font-family: Calibri;
    padding: 10px auto;
}
.articleComponent H1{
  margin-bottom: 35px;
  text-align: center;
  text-decoration: underline;
  font-weight: 900;
}
.articleComponent H2{
  margin-bottom: 20px;
  font-weight: 500;
}
.articleComponent p{
    font-family:  Calibri;
    font-weight: 500;
    margin-bottom: 15px;
    line-height: 2;
    
}